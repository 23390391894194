import React from "react";
import {graphql, Link} from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

import backIcon from "../images/back.svg";

import MemberDetails from "../components/member-details";

const Member = ({data: {strapiTeams: member}}) => {
  return (
    <Layout>
      <SEO title="Team" />
      <Link to="/team">
        <div className="flex mt-8 mb-10 md:my-14">
          {" "}
          <img src={backIcon} className="w-4 h4 mr-3" alt="back" />{" "}
          <span className="font-bold">Meet the Others</span>
        </div>
      </Link>
      <MemberDetails
        member={member}
        noRightBorder={false}
        noLeftMargin={false}
      />
    </Layout>
  );
};

export default Member;
export const query = graphql`
  query getMember($id: String!) {
    strapiTeams(id: {eq: $id}) {
      slug
      firstname
      lastname
      designation
      description
      linkedinUrl
      mediumUrl
      infoUrl
      twitterUrl
      # profilePic {
      #   childImageSharp {
      #     fluid(maxWidth: 960) {
      #       ...GatsbyImageSharpFluid
      #     }
      #   }
      # }
    }
  }
`;
